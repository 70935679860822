.backupcode {
  background-color: $gray-100;
  padding-top: 1rem;
  min-height: calc( 100vh - #{$navbar-height} );
  @include media-breakpoint-down(md) {
    min-height: calc( 100vh - #{$navbar-height}*2 ); }
  &__form {
    display: flex;
    justify-content: space-between;
    &__input {
      flex-grow: 1; }
    &__button {
      margin-top: 1.5rem;
      margin-left: 1rem;
      .btn {
        border-radius: 2rem;
        i {
          font-size: 1.5rem;
          color: $white; } } } }
  &__newlist {
    background: $white;
    border-radius: 2rem;
    border: none;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
    font-weight: bold;
    i {
      background-color: $success;
      border-radius: 50%;
      padding: 0.25rem;
      margin-left: 0.5rem;
      width: 1.75rem;
      color: $white; } } }
