.checklist {
  &__view {
    background-color: $gray-100;
    padding-top: 1rem;
    min-height: calc( 100vh - #{$navbar-height} );
    &__titlebox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      .fa {
        font-size: 1.5rem; } } } }
