.checklist__form {
  margin-top: 1rem;
  padding-bottom: 4rem;
  &__titlebox {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    .form-group {
      margin-bottom: unset; }
    &__actions {
      .fa {
        font-size: 1.5rem; }
      .fa-check {
        color: $success; } } } }
