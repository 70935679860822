.checklist {
  &__item {
    display: flex;
    justify-content: space-between;
    &__title {
      flex-grow: 1;
      min-height: 3rem;
      max-width: calc(100% - 4rem); }
    &__checkbox {
      position: relative;
      width: 4rem;
      label {
        background-color: $white;
        border: 1px solid $gray-300;
        border-radius: 50%;
        cursor: pointer;
        height: 40px;
        left: 0;
        position: absolute;
        top: 0;
        width: 40px; }

      label:after {
        border: 4px solid $success;
        border-top: none;
        border-right: none;
        content: "";
        height: 12px;
        left: 9px;
        opacity: 0;
        position: absolute;
        top: 12px;
        transform: rotate(-45deg);
        width: 20px; }

      input[type="checkbox"] {
        margin-left: 1rem; }

      input[type="checkbox"][disabled] + label {
        background-color: $gray-300; }

      // input[type="checkbox"]:checked + label
      //   background-color: $white
      //   border-color: $white

      input[type="checkbox"]:focus + label {
        border: 1px solid $primary; }

      input[type="checkbox"]:checked + label:after {
        opacity: 1; } }

    &__actions {
      display: flex;
      .fa {
        font-size: 1.5rem; }
      .fa-trash-o {
        color: $danger; }
      .fa-check {
        color: $success; } } }

  &__newitem {
    display: flex;
    justify-content: space-between;
    color: $body-color;
    padding: 0;
    text-align: left;
    margin-bottom: 1rem;
    &:focus {
      outline: none;
      background-color: $gray-300; }
    &__title {
      flex-grow: 1;
      min-height: 3rem;
      hr {
        margin-bottom: 0; } }
    &__iconcontainer {
      position: relative;
      width: 4rem;
      &__icon {
        background-color: $white;
        border: 1px solid $gray-300;
        border-radius: 50%;
        cursor: pointer;
        height: 40px;
        left: 0;
        position: absolute;
        top: 0;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 2rem; } } } } }
