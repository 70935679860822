.fourofour {
  min-height: calc( 100vh - #{$navbar-height} );
  @include media-breakpoint-down(md) {
    min-height: calc( 100vh - #{$navbar-height}*2 ); }
  &__content {
    height: calc( 100vh - #{$navbar-height} );
    @include media-breakpoint-down(md) {
      height: calc( 100vh - #{$navbar-height}*2); }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }
