.navigationbar {
  z-index: 999;
  height: $navbar-height;
  position: fixed;
  width: 100vW;
  transition: background-color 500ms linear;
  box-shadow: $box-shadow;

  .navbar-brand {
    padding: 0; }
  .navbar {
    @include media-breakpoint-down(md) {
      justify-content: center; } }
  &--sticky {
    background-color: $dark; }
  &__back {
    &--mobile {
      left: 1rem;
      top: 1px;
      position: fixed;
      font-size: 2rem; }
    &--desktop {} }


  &__nav {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    width: calc(100% - 200px);
    &__items {
      width: 100%;
      justify-content: space-around;
      img {
        color: $primary;
        @include media-breakpoint-up(lg) {
          margin-right: 0.5rem; } } }
    .navbar {
      @include media-breakpoint-up(lg) {
        padding: 0.3rem 1rem; } }
    .nav-link {
      border-bottom: 4px solid transparent;
      color: $gray-800;
      @include media-breakpoint-up(lg) {
        font-size: 1.375rem; }
      &.active {
        border-bottom: 4px solid $primary; } }
    @include media-breakpoint-down(md) {
      background-color: $white;
      position: fixed;
      left: 0;
      width: 100%;
      top: unset;
      bottom: 0;
      box-shadow: $box-shadow;
      .navbar {
        padding: 0; }
      &__items {
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.75rem;
        text-align: center;
        height: $navbar-height;
        img {
          width: 100%; } } } } }

.navplaceholder {
  height: 70px;
  width: 100%;
  background-color: $dark;
  position: fixed;
  top: 0;
  z-index: 990; }
.navplaceholder:after {
  position: relative;
  height: 70px; }
.recipedisplay {
  padding-top: 8rem; }

.navbar-dark .navbar-nav .dropdown-menu.show .nav-link {
  color: $body-color; }

.navbar-collapse {
  @include media-breakpoint-down(xs) {
    margin: 0.5rem -1rem;
    background-color: $white;
    padding: 1rem;
    .navbar-nav {
      .nav-link {
        color: rgba(0, 0, 0, 0.5); }
      .show > .nav-link, .active > .nav-link, .nav-link.show, .nav-link.active, .nav-link:focus {
        color: $dark; } } } }

.navbar-nav .dropdown-menu .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
