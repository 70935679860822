.relatedlinks__list {
  &__col {
    @include media-breakpoint-down(xs) {
      padding: 0; }
    @include media-breakpoint-up(sm) {
      margin-bottom: 2rem; } }
  &__item {
    background-color: $white;
    height: 100%;
    &__title {
      font-weight: bold;
      color: $primary; }
    .card-link:hover {
      text-decoration: underline; } } }
