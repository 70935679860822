.loadingcontent {
  // min-height: calc( 100vh - #{$navbar-height} )
  text-align: center;
  &__progressbar {
    height: 0.5rem; }
  &__content {
    height: calc( 100vh - #{$navbar-height} - 0.5rem );
    @include media-breakpoint-down(md) {
      height: calc( 100vh - #{$navbar-height}*2 - 0.5rem); }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }
