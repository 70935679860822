.checklist__confirmed {
  background-color: $gray-100;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  &__flex {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  &__icon {
    background-color: $white;
    color: $success;
    font-size: 4rem;
    width: 128px;
    height: 128px;
    padding: 1rem;
    padding-left: 2rem;
    border-radius: 50%;
    margin-bottom: 2rem; }
  &__link {
    margin-top: 4rem;
    padding: 0.4rem 4rem;
    border-radius: 2rem;
    position: relative;
    color: $white;
    font-weight: bold;
    &__icon {
      background-color: $white;
      color: $success;
      border-radius: 50%;
      padding-top: 4px;
      padding-left: 3px;
      position: absolute;
      top: 3px;
      right: 4px;
      width: 36px;
      height: 36px; } } }
