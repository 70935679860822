.checklistactions {
  padding-bottom: 1rem;
  &__action {
    width: 100%;
    text-align: left;
    color: $body-color;
    padding-left: 0;
    margin-top: 1rem;
    &__icon {
      background-color: $gray-300;
      color: $gray-700;
      border-radius: 50%;
      padding: 6px;
      margin-right: 1rem; } }
  &__add {
    border-radius: 2rem;
    position: relative;
    color: $white;
    font-weight: bold;
    &__icon {
      background-color: $white;
      color: $success;
      border-radius: 50%;
      padding-top: 4px;
      padding-left: 3px;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 36px;
      height: 36px; } } }
