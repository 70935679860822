.checklists {
  &__view {
    background-color: $gray-100;
    padding-top: 1rem;
    min-height: calc( 100vh - #{$navbar-height} );
    @include media-breakpoint-down(md) {
      min-height: calc( 100vh - #{$navbar-height}*2 ); } }
  &__hero {
    &__top {
      position: relative; }
    &__help {
      .fa {
        font-size: 1.5em; } }
    &__modal {
      max-height: calc(100vh - 1rem);
      .modal-content {
        max-height: calc(100vh - 1rem); }
      .modal-body {
        overflow-y: scroll; }
      &__header {
        h5 {
          font-size: 1.5rem; } } }
    &__newlist {
      position: absolute;
      top: 0;
      right: 0;
      background: $white;
      border-radius: 2rem;
      border: none;
      box-shadow: 0 1px 2px rgba(0,0,0,0.3);
      font-weight: bold;
      i {
        @media only screen and (max-width: 349px) {
          display: none; }
        background-color: $success;
        border-radius: 50%;
        padding: 0.25rem;
        margin-left: 0.5rem;
        width: 1.5rem;
        color: $white; } }
    &__backupcode {
      font-size: $font-size-lg; } }
  &__list {
    margin-top: 1rem;
    padding-bottom: 1rem;
    &__item {
      display: flex;
      color: $body-color;
      margin-bottom: 1rem;
      &:focus {
        outline: none;
        background-color: $gray-300; }
      &:hover {
        text-decoration: none; }
      &__imgcontainer {
        width: 4.75rem;
        min-height: 60px;
        .sb-avatar {
          margin-right: 1rem;
          box-shadow: 0 6px 12px rgba(183, 230, 230, 0.4); } }
      &__content {
        flex-grow: 1;
        min-height: 60px;
        hr {
          margin-bottom: 0; }
        .badge {
          margin-left: 0.5rem; } }
      &__date {
        float: right;
        color: $gray-700;
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-normal; } } } }

