.samples__list {
  &__view {
    background-color: $white;
    min-height: calc( 100vh - #{$navbar-height} );
    @include media-breakpoint-down(md) {
      min-height: calc( 100vh - #{$navbar-height}*2 ); }
    padding-bottom: 1rem;
    &__content {
      &__entry {
        color: $body-color;
        &:hover {
          text-decoration: none;
          .card {
            background-color: $gray-100; } }
        @include media-breakpoint-down(xs) {
          padding: 0; }
        .card {
          background-color: $gray-200;
          .card-title {
            color: $primary; }
          .card-body {
            padding-right: 3.5rem; }
          hr {
            margin-bottom: 0; } } }
      &__link {
        position: absolute;
        top: 40%;
        right: 1rem;
        border-radius: 50%; } } }

  &__filters {
    &__top {
      display: flex;
      &__search {
        flex-grow: 1; }
      &__toggle {
        margin-top: 1.7rem;
        padding-top: 2px;
        padding-bottom: 1px; } } } }

