// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

///mixins
@import "styles/mixins/fontface";

//fonts
@include fontFace('SourceSansPro-Regular', './assets/fonts/SourceSansPro-Regular');
@include fontFace('SourceSansPro-Bold', './assets/fonts/SourceSansPro-Bold');

// theme
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrapvariables";
@import "styles/variables";

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap";

// stylesheets containers
@import "styles/containers/Root";
@import "styles/containers/App";
@import "styles/containers/Backupcode";
@import "styles/containers/ChecklistDisplay";
@import "styles/containers/ChecklistConfirmed";
@import "styles/containers/Home";
@import "styles/containers/Imprint";
@import "styles/containers/Dashboard";
@import "styles/containers/ItemDisplay";
@import "styles/containers/FourOFour";

//stylesheets components
@import "components/ChecklistActions/ChecklistActions";
@import "components/ChecklistForm/ChecklistForm";
@import "components/ChecklistView/ChecklistView";
@import "components/ChecklistItem/ChecklistItem";
@import "components/ChecklistsListView/ChecklistsListView";
@import "components/ContentBlockFullWidth/ContentBlockFullWidth";
@import "components/ContentBlockShowMore/ContentBlockShowMore";
@import "components/SampleView/SampleView";
@import "components/SamplesListView/SamplesListView";
@import "components/About/About";
@import "components/FieldInput/FieldInput";
@import "components/FileInput/FileInput";
@import "components/ItemView/ItemView";
@import "components/ItemsListView/ItemsListView";
@import "components/LoadingContent/LoadingContent";
@import "components/Login/Login";
@import "components/Navbar/Navbar";
@import "components/OfflineBar/OfflineBar";
@import "components/PasswordRecovery/PasswordRecovery";
@import "components/PasswordReset/PasswordReset";
@import "components/Register/Register";
@import "components/RelatedLinksListView/RelatedLinksListView";
@import "components/SectionsItem/SectionsItem";
@import "components/SectionsForm/SectionsForm";
@import "components/InfoButton/InfoButton";


body {
  margin: 0;
  padding: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

// TODO add production website styling
// a[href^="http://"]:not([href*="productionwebsite.com"]),
// a[href^="https://"]:not([href*="productionwebsite.com"]),
// a[href^="//"]:not([href*="productionwebsite.com"]),
a[href^="http://"]:not([href*="bkry-rehadat-web.herokuapp.com"]),
a[href^="https://"]:not([href*="bkry-rehadat-web.herokuapp.com"]),
a[href^="//"]:not([href*="bkry-rehadat-web.herokuapp.com"]) {
  &:after {
    font-family: 'FontAwesome';
    content: " \f08e"; } }

.form-group {
  label {
    font-weight: bold; } }



.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }

#checklists, #imprint, #related_links, #samples {
  padding-top: $navbar-height;
  @include media-breakpoint-down(md) {
    padding-bottom: $navbar-height; } }

#checklist, #checklistdisplay, #sample {
  padding-top: $navbar-height; }

.btn-link {
  &:focus, &:hover {
    text-decoration: none;
    background-color: $gray-300; } }


.hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding-bottom: 10px; }
