.home {
  padding-top: $navbar-height;
  @include media-breakpoint-down(md) {
    padding-bottom: $navbar-height; }
  &__tabsection {
    background-color: $white;
    .nav-tabs {
      background-color: $white;
      .nav-item {
        width: 33%;
        text-align: center;
        .nav-link {
          padding: 1rem;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media-breakpoint-down(sm) {
            font-size: $font-size-base; } } } }
    .tab-content {
      padding-top: 1rem; } }
  &__infobutton {
    float: right;
    .fa {
      font-size: 1.5em; } } }
