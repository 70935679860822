.checklistdisplay {
  background-color: $gray-100;
  padding-top: 1rem;
  min-height: 100vh;
  &__done {
    position: fixed;
    bottom: 1rem;
    left: 0;
    right: 0;
    &__link {
      border-radius: 2rem;
      color: $white;
      font-weight: bold; } } }
